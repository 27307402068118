import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Helmet } from "react-helmet"
import Layout from "./components/layout"

const Portfolio = () =>
  <Layout pageTitle="Portfolio">
    <Helmet>
      <title>Portfolio</title>
      <meta property="og:description" content="web development projects and case studies"></meta>
      <meta property="og:title" content="Portfolio"></meta>
    </Helmet>
    <div className="content content--grid-sidebar">
      <aside>
        <div className="project-title_nav">
          <h2>Projects</h2>
        <p className="project-title BFSFF">
          <Link
          className="project-link"
          activeStyle={{ textDecoration: "underline" }}
          to="#sectionBFSFF">
            BFS Filmfest
          </Link>
        </p>
        <p className="project-title AV">
              <Link
                className="project-link"
                activeStyle={{ textDecoration: "underline" }}
                to="#sectionAV">
                Alx Velozo
              </Link>
            </p>
        <p className="project-title YWFAW">
          <Link
          className="project-link"
          activeStyle={{ textDecoration: "underline" }}
          to="#sectionYWFAW">
            YWFAW
          </Link>
        </p>
        <p className="project-title LEAFLY">
          <Link
          className="project-link"
          activeStyle={{ textDecoration: "underline" }}
          to="#sectionLEAFLY">
            Leafly
          </Link>
        </p>
        </div>
      </aside>
      <main>
        <section id="sectionBFSFF">
        <StaticImage
          src="../assets/BFSFFWebsite.png"
          alt="Black Femme Supremacy Film Fest Webpages"
          layout="constrained"
        />
        <div className="project" id="BFSFF">
          <div className="project_heading">
            <h2> <a
                  className="projectLink"
                  href="https://www.bfsfilmfest.com"
                  target="_blank"
                  rel="noopener"
                >
                  Black Femme Supremacy Film Fest
                </a></h2><br />
            <small>Feb. - Aug. 2023</small>
          </div>
          <div className="project_details">
          <div className="project_tools">
              <p> _Client:<br />
                Founder -
                Nia Hampton
              </p>
              <p>
                _Technology:<br />
                GoDaddy/
                Wordpress<br />
                Squarespace <br />
                HostGator <br />
                html, css
              </p>
            </div>
            <div className="project_descp">
              <p>
              Migrated data (media, content) and redesigned website. Developed reusable components,
               standardized themes, and implemented design modifications to page content (links, images)
                in accordance with Web Content Accessibility Guidelines (WCAG).
              </p>
            </div>
          </div>
        </div>
        </section>
        <section id="sectionAV">
            <StaticImage
              src="../assets/AlxVHome.png"
              alt="Client's homepage which features examples of their work, cement sculptures, as navigation links."
              layout="constrained"
            />
            <div className="project" id="AV">
              <div className="project_heading">
                <h2><a
                    className="projectLink"
                    href="https://alxvelozo.com"
                    target="_blank"
                    rel="noopener"
                  >Alx Velozo</a></h2><br />
                <small>2022</small>
              </div>
              <div className="project_details">
                <div className="project_tools">
                  <p> _Client:<br />
                    Alx Velozo<br />
                  </p>
                  <p>
                    _Technology:<br />
                    Cargo.site<br />
                    html, css, js
                  </p>
                </div>
                <div className="project_descp">
                  <p>
                  Total re-write of old raw HTML website to the Cargo.site platform. 
                  Replicated design and functionality of site including image mapped 
                  links on Home page through custom coding. Rigorous implemention of 
                  accessible design with alt text, clear and bold fonts.<br />
                  </p>
                </div>
              </div>
            </div>
          </section>
        <section id="sectionYWFAW">
        <StaticImage
          src="../assets/YWFAW.png"
          alt="A couple notebooks set next to each other. The cover of the notebooks features different paper fibers, some smooth, some rough, cut, formed, and layered to produce gentle hills that open to reveal moon and sky."
          layout="constrained"
        />
        <div className="project" id="YWFAW">
          <div className="project_heading">
            <h2> You Will... Find A Way notebook series
              </h2><br />
            <small>2024</small>
          </div>
          <div className="project_details">
            <div className="project_tools">
              <p> _Employer:<br />
                Self
              </p>
              <p>
                _Material:<br />
                Cardstock, Plant fibers, Tissue and Construction Papers
              </p>
            </div>
            <div className="project_descp">
              <p>
              A series of 12 mini notebooks. Each 30-page booklet offers a choice between dotted or plain paper, housed in a unique, textured cover. Layers of colorful paper fibers formed gentle hills that opened to reveal a starlit sky, symbolizing guidance. A powerful message – "I will... find a way" – graced the cover and inside, printed using the traditional linocut technique.
                          </p>
            </div>
          </div>
        </div>
        </section>
        <section id="sectionLEAFLY">
        <StaticImage
          src="../assets/LeaflyWebMenu.png"
          alt="dispensary product menus"
          layout="constrained"
        />
        <div className="project" id="LEAFLY">
          <div className="project_heading">
            <h2> <a
                  className="projectLink"
                  href="https://www.leafly.com/dispensary-info/storehouse/menu"
                  target="_blank"
                  rel="noopener"
                >Leafly - Dispensary Product Menus 
                </a></h2><br />
            <small>2018 - 2020</small>
          </div>
          <div className="project_details">
            <div className="project_tools">
              <p> _Employer:<br />
                Leafly
              </p>
              <p>
                _Technology:<br />
                Express/<br />
                NodeJs, React/NextJs
              </p>
            </div>
            <div className="project_descp">
              <p>
              Transformed Figma designs into fully realized User Interfaces for client-side applications.
              Created highly responsive UIs that adapt to the size and position of any device.
                          </p>
            </div>
          </div>
        </div>
        </section>
      </main>
    </div>
  </Layout>

export default Portfolio
/*
IMG or gif???
ME date
platform cargo (html, css, javascript)
- collaborated with local designer to create an experience that
embodied the personality ME wanted to convey (dominant, sensual,...). Layout, color scheme, and type faces
- form embeds
- custom styling and icons
- custom scripting
- custom svgs icons for FAQ page and social netPortfolioing bar

IMG
Alx Velozo
platform cargo (html, css)
- migrated from pure html hosted on web server to a managed platform
for better ease of use
-collaborated with client to make sure prior site was replicated
- replaced old site with redirect link

IMG
IMSLBB
platform wix(js, js web token)
custom scripting to integrate third-party API and keep apps secure
configure live streaming features
form embeds
*/

/**
 * Change url for seo
 * check ally for accesibilty headings
 * hide aside display for mobile view
 */